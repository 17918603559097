import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Slide, Snackbar } from '@mui/material';

const FingoSnackBar = ({ open, setOpen, message, severity, actionButton, autoHideDuration }) => (
  <Snackbar
    open={open}
    autoHideDuration={autoHideDuration}
    TransitionComponent={Slide}
    onClose={() => setOpen(false)}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    sx={{ marginTop: 8, width: '100%' }}
    action={(
      <>
        {actionButton && <Button sx={{ color: 'white' }} onClick={actionButton.action}>{actionButton.text}</Button>}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    )}
    severity={severity} // Esto no se ocupa todavía pero nos va a servir para diferenciar
    message={message}
  />
);

export default FingoSnackBar;

FingoSnackBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string,
  severity: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  actionButton: PropTypes.object,
  autoHideDuration: PropTypes.number,
};
FingoSnackBar.defaultProps = {
  actionButton: null,
  severity: 'error',
  message: 'Ha ocurrido un error',
  autoHideDuration: 6000,
};
