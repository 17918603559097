import { Skeleton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useExternalOfferShoppingCart } from '../../hooks';
import { StepTypography } from '../typography';

const ExternalOfferShoppingCart = ({ bankId, setBankId }) => {
  const { paragraphs, loading } = useExternalOfferShoppingCart({
    bankId,
    setBankId,
  });

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={100} />;
  }

  return (
    <Stack flexGrow={1} spacing={2}>
      {paragraphs.map(({ id, title, content }) => (
        <Stack key={id} spacing={1}>
          <StepTypography stepNumber={id}>{title}</StepTypography>
          {content}
        </Stack>
      ))}
    </Stack>
  );
};

ExternalOfferShoppingCart.propTypes = {
  bankId: PropTypes.string.isRequired,
  setBankId: PropTypes.func.isRequired,
};

export default ExternalOfferShoppingCart;
