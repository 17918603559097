import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { CurrencyFilter } from '../filters';

const ControlledSelectMenu = (
  { options, value, setValue, onChange, currency, setCurrency, showCurrencyFilter },
) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const selectedValue = options.find((option) => option === value);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, option) => {
    setValue(option);
    onChange();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {selectedValue}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === selectedValue}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {showCurrencyFilter && (
        <CurrencyFilter currency={currency} setCurrency={setCurrency} />
      )}
    </div>
  );
};

ControlledSelectMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  currency: PropTypes.string,
  setCurrency: PropTypes.func,
  showCurrencyFilter: PropTypes.bool,
};

ControlledSelectMenu.defaultProps = {
  onChange: () => {},
  currency: '',
  setCurrency: () => {},
  showCurrencyFilter: false,
};

export default ControlledSelectMenu;
