import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Description from '@mui/icons-material/Description';
import { Tooltip } from '@mui/material';
import CompanyProfileDialog from '../dialogs/CompanyProfileDialog';

const RelatedDocumentsMenu = ({ masterEntity }) => {
  const [openProfile, setOpenProfile] = useState(false);

  const toggleProfile = () => {
    setOpenProfile(!openProfile);
  };

  return (
    <>
      <Tooltip title="Ver Perfil">
        <Description
          sx={{ cursor: 'pointer', width: 15, paddingBottom: 1 }}
          color="primary"
          onClick={toggleProfile}
        />
      </Tooltip>
      <CompanyProfileDialog
        masterEntityId={masterEntity.id}
        open={openProfile}
        setOpen={toggleProfile}
      />
    </>
  );
};

RelatedDocumentsMenu.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default RelatedDocumentsMenu;
