import { useDynamicFingoLogo } from '@fingo/lib/hooks';
import { Button, Icon, Stack, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MutationNotification = ({ messageParagraph }) => {
  const fingoLogo = useDynamicFingoLogo();
  const history = useHistory();

  return (
    <Stack
      borderRadius={4}
      py={2}
      sx={{
        px: { xs: 0, sm: 0, md: 4, lg: 10 },
        maxHeight: { xs: '100%', sm: '100%', md: 'inherit', lg: 'inherit' },
        mx: { xs: 0, s: 'auto' },
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '10%',
      }}
      spacing={2}
    >
      <Icon sx={{ height: 80, width: '100%' }}>
        <img src={fingoLogo} style={{ height: '100%' }} alt="sii" />
      </Icon>
      <Typography
        align="center"
        variant="h1"
      >
        {messageParagraph.header}
      </Typography>
      <Typography
        align="center"
        variant="h2"
        pb={1}
      >
        {messageParagraph.body}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => history.push('/')}
      >
        Volver a Fingo
      </Button>
    </Stack>
  );
};

MutationNotification.propTypes = {
  messageParagraph: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
  }),
};

MutationNotification.defaultProps = {
  messageParagraph: {
    header: '',
    body: '',
  },
};

export default MutationNotification;
