import React from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import Cancel from '@mui/icons-material/Cancel';
import WatchLaterRounded from '@mui/icons-material/WatchLaterRounded';
import CheckCircle from '@mui/icons-material/CheckCircle';

const AbstratStatus = ({ statusArray, row, title, ...props }) => (
  <Stack direction="column" {...props}>
    <Typography variant="body2" width="inherit" noWrap textAlign="center">
      {title}
    </Typography>
    <Stack direction="row" spacing={0.4} justifyContent="center">
      {statusArray.map(({ Icon, color, tooltip, labelName, key }) => (
        <Tooltip key={key} title={tooltip}>
          <Stack direction="row" alignItems="center">
            <Icon color={color} sx={{ height: 20 }} />
            <Typography variant="caption">
              {row[labelName] || 0}
            </Typography>
          </Stack>
        </Tooltip>
      ))}
    </Stack>
  </Stack>
);

export default AbstratStatus;

AbstratStatus.propTypes = {
  statusArray: PropTypes.arrayOf(PropTypes.shape({
    Icon: PropTypes.elementType.isRequired,
    color: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  })),
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

AbstratStatus.defaultProps = {
  statusArray: [
    {
      key: 'transfered',
      tooltip: 'Factura pagada',
      labelName: 'TRANSFERED',
      color: 'success',
      Icon: CheckCircle,
    },
    {
      key: 'pending-transfer',
      tooltip: 'Pendiente de giro',
      labelName: 'PENDING_TRANSFER',
      color: 'warning',
      Icon: WatchLaterRounded,
    },
    {
      key: 'pending-cession',
      tooltip: 'Pendiente de cesión',
      labelName: 'PROCESSING',
      color: 'error',
      Icon: Cancel,
    },
  ],
};
